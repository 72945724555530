import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import i18next from "i18next";

function GastosContenedorCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS_CONTENEDOR = process.env.REACT_APP_URL_GASTOS_CONTENEDOR;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [fechaEntrega, setFechaEntrega] = useState(hoy);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [contenedores, setContenedores] = useState([]);
  const [value, setValue] = useState("");
  const [selectedContenedor, setSelectedContenedor] = useState("");
  const [selectedContenedorNombre, setSelectedContenedorNombre] = useState("");
  const [ETD, setETD] = useState("");
  const [ETA, setETA] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useMemo(() => {
    // if(user.tipo == "Broker"){
    //   setSelectedBroker(user.brokers)
    // }

    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CONTENEDORES}Gastos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allContenedores = res.data;
        setContenedores(allContenedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_BROKERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allBrokers = res.data;
        setBrokers(allBrokers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const calcula = (e) => {
    setSubtotal(e);
    setTotal(parseFloat(e) + parseFloat(iva));
  };

  const calculaIVA = (e) => {
    setIva(e);
    setTotal(parseFloat(subtotal) + parseFloat(e));
  };

  const saveGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_GASTOS_CONTENEDOR,
        {
          fecha,
          // fechaEntrega,
          subtotal,
          iva,
          total,
          abonos: 0,
          saldo: total,
          descripcion,
          conceptosGastos: selectedConcepto,
          autorizado: "No",
          contenedores: selectedContenedor,
          brokers: selectedBroker,
          clientes: selectedCliente,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Gasto",
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  function jalaContenedor(e) {
    setSelectedContenedor(e);
console.log("hola", e)
    contenedores.map((a) => {
      if (a._id == e) {
        setSelectedCliente(a.clientes[0]._id);
        setETD(a.etd);
        setETA(a.etaEstimada);
      }
    });
  }

  const filteredContenedores = selectedBroker
    ? contenedores.filter(
        (contenedor) => contenedor.brokerId === selectedBroker.id
      )
    : [];

  const options = filteredContenedores.map((option) => {
    const junta =
      option.numeroReferencia +
      " " +
      option.referencia +
      " " +
      option.referenciaExtra;
    const firstLetter = option.numeroReferencia[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastosCreate ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">{t("Nuevo Costo Importacion")}</h3>
            <Form onSubmit={saveGasto}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                  {/*<Col md={6}>
                    <Label className="mr-sm-2">
                      {t("Fecha Entrega")}
                    </Label>
                    <Input
                      type="date"
                      placeholder="FechaEntrega"
                      value={fechaEntrega}
                      required
                      onChange={(e) => {
                        setFechaEntrega(e.target.value);
                      }}
                    />
                  </Col> */}

                  <Col md={6}>
                    <Label className="mr-sm-2">Broker</Label>
                    <Input
                      // bsSize="sm"
                      type="select"
                      required
                      value={selectedBroker}
                      onChange={(e) => {
                        setSelectedBroker(e.target.value);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {brokers
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (user.tipo == "Broker") {
                            if (a._id == user.brokers) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          } else {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">{t("Contenedor")}</Label>

                    <Autocomplete
                      size="small"
                      value={selectedContenedorNombre}
                      onChange={(event, selectedContenedor) => {
                        jalaContenedor(selectedContenedor._id);
                        setSelectedContenedorNombre(selectedContenedor);
                        // setValue(selectedContenedor)
                        // setSelectedContenedor(selectedContenedor._id)
                        // setSelectedContenedorNombre(selectedContenedor.referencia)
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.numeroReferencia} / {option.referencia} /{" "}
                          {option.referenciaExtra}
                        </React.Fragment>
                      )}
                    />

                    {/* <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedContenedor}
                        onChange={(e) => jalaContenedor(e.target.value)}
                      >
                        <option value="0">{t("Selecciona")}</option>
                        {contenedores
                          .sort((a, b) => (a.numeroReferencia > b.numeroReferencia ? 1 : -1))
                          .map((a) => {


                            if(selectedBroker == a.brokers[0]._id)
                              return <option value={a._id}>{a.numeroReferencia} {a.referenciaExtra}</option>;
                          })}
                      </Input> */}
                  </Col>

                  <Col md={6}>
                    <Label className="mr-sm-2">{t("Cliente")}</Label>
                    <Input
                      // bsSize="sm"
                      type="select"
                      required
                      value={selectedCliente}
                      disabled
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {clientes
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">{t("ETD")}</Label>
                    <Input
                      // bsSize="sm"
                      type="date"
                      required
                      value={ETD}
                      disabled
                    />
                  </Col>

                  <Col md={6}>
                    <Label className="mr-sm-2">{t("ETA")}</Label>
                    <Input
                      // bsSize="sm"
                      type="date"
                      required
                      value={ETA}
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">{t("Concepto")}</Label>
                    <Input
                      // bsSize="sm"
                      type="select"
                      required
                      value={selectedConcepto}
                      onChange={(e) => setSelectedConcepto(e.target.value)}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {conceptosGastos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      {t("Descripcion")}
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      {t("Total")}
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Subtotal"
                      value={subtotal}
                      required
                      onChange={(e) => calcula(e.target.value)}
                    />
                  </Col>
                  {/* <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      IVA
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="IVA"
                      value={iva}
                      required
                      onChange={(e) => calculaIVA(e.target.value)}
                    />
                  </Col> */}
                  {/* <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      {t("Total")}
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder={total}
                      disabled
                    />
                  </Col> */}
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      {t("Guardar")}
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      {t("Guardar")}
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/Gastos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    {t("Regresar")}
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default GastosContenedorCreate;
