import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import PasswordColaboradores from "./PasswordColaboradores";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_FORWARDERS = process.env.REACT_APP_URL_FORWARDERS;
  const [usuarios, setUsuarios] = useState([]);
  const [t, i18n] = useTranslation("global");
  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });
const [activo, setActivo] = useState("");
const [idioma, setIdioma] = useState();
const ITEMS_PER_PAGE = 50

const [idEdit, setIdEdit] = useState();
const [nombre, setNombre] = useState();
const [apellido, setApellido] = useState();
const [email, setEmail] = useState();
const [tipo, setTipo] = useState();
const [is_active, setIsActive] = useState();
const [area, setArea] = useState();
const [brokers, setBrokers] = useState([]);
const [selectedBroker, setSelectedBroker] = useState("");
const [idBrokFor, setIdBrokFor] = useState("");
const [forwarders, setForwarders] = useState([]);
const [selectedForwarder, setSelectedForwarder] = useState("");

const [idPermisos, setIdPermisos] = useState();

const [menu_usuarios, setMenuUsuarios] = useState();
const [usuarios_create, setUsuariosCreate] = useState();
const [usuarios_permisos, setUsuariosPermisos] = useState();

const [menu_logs, setMenuLogs] = useState();
const [menu_gastos, setMenuGastos] = useState();

const [menuClientes, setMenuClientes] = useState()
const [menuArticulos, setMenuArticulos] = useState()
const [menuNavieras, setMenuNavieras] = useState()
const [menuBrokers, setMenuBrokers] = useState()
const [menuForwarders, setMenuForwarders] = useState()
const [menuEmpresas, setMenuEmpresas] = useState()
const [menuContenedores, setMenuContenedores] = useState()
const [contenedoresCreate, setContenedoresCreate] = useState()
const [contenedoresEdit, setContenedoresEdit] = useState()
const [contenedoresReporte, setContenedoresReporte] = useState()
const [menuSurtidos, setMenuSurtidos] = useState()
const [menuVentas, setMenuVentas] = useState()
const [gastosCreate, setGastosCreate] = useState()
const [gastosAutorizar, setGastosAutorizar] = useState()
const [menuPagos, setMenuPagos] = useState()
const [pagosCreate, setPagosCreate] = useState()
const [estadoCuentaBrokers, setEstadoCuentaBrokers] = useState()
const [estadoCuentaClientes, setEstadoCuentaClientes] = useState()
const [menuAbonos, setMenuAbonos] = useState()
const [abonosCreate, setAbonosCreate] = useState()
const [menuCatalogos, setMenuCatalogos] = useState()
const [menuAdmin, setMenuAdmin] = useState()
const [menuReportes, setMenuReportes] = useState()

const [emailSis, setEmailSis] = useState("admin@sistemify.com");

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);
const [text, setText] = useState(false);

const [modalPermisos, setModalPermisos] = useState(false);
const togglePermisos = () => setModalPermisos(!modalPermisos);

const [modalPassword, setModalPassword] = useState(false);
const togglePassword = () => setModalPassword(!modalPassword);
const [idUser, setIdUser] = useState("")

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        let arrayTabla = allUsuarios
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
        .map((a) => {
          if(a.tipo == "Admin"){
            return {
              _id:a._id,
              nombre: a.nombre,
              apellido: a.apellido,
              email: a.email,
              idioma: a.idioma,
              tipo: a.tipo,
              brokFor: "NA",
              idBrokFor: "",
              is_active: a.is_active,
              menu_usuarios: a.menu_usuarios,
              usuarios_create: a.usuarios_create,
              usuarios_permisos: a.usuarios_permisos,
              menu_logs: a.menu_logs,
              menu_gastos: a.menu_gastos,
              menuClientes: a.menuClientes,
              menuArticulos: a.menuArticulos,
              menuNavieras: a.menuNavieras,
              menuBrokers: a.menuBrokers,
              menuForwarders: a.menuForwarders,
              menuEmpresas: a.menuEmpresas,
              menuContenedores: a.menuContenedores,
              contenedoresCreate: a.contenedoresCreate,
              contenedoresEdit: a.contenedoresEdit,
              contenedoresReporte: a.contenedoresReporte,
              menuSurtidos: a.menuSurtidos,
              menuVentas: a.menuVentas,
              gastosCreate: a.gastosCreate,
              gastosAutorizar: a.gastosAutorizar,
              menuPagos: a.menuPagos,
              pagosCreate: a.pagosCreate,
              estadoCuentaBrokers: a.estadoCuentaBrokers,
              estadoCuentaClientes: a.estadoCuentaClientes,
              menuAbonos: a.menuAbonos,
              abonosCreate: a.abonosCreate,
              menuCatalogos: a.menuCatalogos,
              menuAdmin: a.menuAdmin,
              menuReportes: a.menuReportes,
            }
          }else if(a.tipo == "Broker"){
            return {
              _id:a._id,
              nombre: a.nombre,
              apellido: a.apellido,
              email: a.email,
              idioma: a.idioma,
              tipo: a.tipo,
              brokFor: a.brokers[0].name,
              idBrokFor: a.brokers[0]._id,
              is_active: a.is_active,
              menu_usuarios: a.menu_usuarios,
              usuarios_create: a.usuarios_create,
              usuarios_permisos: a.usuarios_permisos,
              menu_logs: a.menu_logs,
              menu_gastos: a.menu_gastos,
              menuClientes: a.menuClientes,
              menuArticulos: a.menuArticulos,
              menuNavieras: a.menuNavieras,
              menuBrokers: a.menuBrokers,
              menuForwarders: a.menuForwarders,
              menuEmpresas: a.menuEmpresas,
              menuContenedores: a.menuContenedores,
              contenedoresCreate: a.contenedoresCreate,
              contenedoresEdit: a.contenedoresEdit,
              contenedoresReporte: a.contenedoresReporte,
              menuSurtidos: a.menuSurtidos,
              menuVentas: a.menuVentas,
              gastosCreate: a.gastosCreate,
              gastosAutorizar: a.gastosAutorizar,
              menuPagos: a.menuPagos,
              pagosCreate: a.pagosCreate,
              estadoCuentaBrokers: a.estadoCuentaBrokers,
              estadoCuentaClientes: a.estadoCuentaClientes,
              menuAbonos: a.menuAbonos,
              abonosCreate: a.abonosCreate,
              menuCatalogos: a.menuCatalogos,
              menuAdmin: a.menuAdmin,
              menuReportes: a.menuReportes,
            }
          }else if(a.tipo == "Forwarder"){
            return {
              _id:a._id,
              nombre: a.nombre,
              apellido: a.apellido,
              email: a.email,
              idioma: a.idioma,
              tipo: a.tipo,
              brokFor: a.forwarders[0].name,
              idBrokFor: a.forwarders[0]._id,
              is_active: a.is_active,
              menu_usuarios: a.menu_usuarios,
              usuarios_create: a.usuarios_create,
              usuarios_permisos: a.usuarios_permisos,
              menu_logs: a.menu_logs,
              menu_gastos: a.menu_gastos,
              menuClientes: a.menuClientes,
              menuArticulos: a.menuArticulos,
              menuNavieras: a.menuNavieras,
              menuBrokers: a.menuBrokers,
              menuForwarders: a.menuForwarders,
              menuEmpresas: a.menuEmpresas,
              menuContenedores: a.menuContenedores,
              contenedoresCreate: a.contenedoresCreate,
              contenedoresEdit: a.contenedoresEdit,
              contenedoresReporte: a.contenedoresReporte,
              menuSurtidos: a.menuSurtidos,
              menuVentas: a.menuVentas,
              gastosCreate: a.gastosCreate,
              gastosAutorizar: a.gastosAutorizar,
              menuPagos: a.menuPagos,
              pagosCreate: a.pagosCreate,
              estadoCuentaBrokers: a.estadoCuentaBrokers,
              estadoCuentaClientes: a.estadoCuentaClientes,
              menuAbonos: a.menuAbonos,
              abonosCreate: a.abonosCreate,
              menuCatalogos: a.menuCatalogos,
              menuAdmin: a.menuAdmin,
              menuReportes: a.menuReportes,
            }
          }


        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setUsuarios(allUsuarios);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_BROKERS}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allBrokers = response.data;
          setBrokers(allBrokers);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
      .get(`${URL_FORWARDERS}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allForwarders = response.data;
          setForwarders(allForwarders);
        })
        .catch((err) => {
          console.log(err);
        });

  },[]);
  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Tipo", field: "tipo", sortable: true },
    { name: "Brok / For", field: "brokFor", sortable: true },
    // { name: "Idioma", field: "idioma", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.tipo.toLowerCase().includes(search.toLowerCase()) ||
                comment.brokFor.toLowerCase().includes(search.toLowerCase()) ||
                comment.idioma.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase())
        );
    }


    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    if(emailSis){
      computedComments = computedComments.filter((e)=>
      !e.email.includes(emailSis)
      ) 
    }     

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, activo]);

function Edit(idEdit, nombre, apellido, email, idioma, tipo, brokFor){
  setIdEdit(idEdit)
  setNombre(nombre)
  setIdioma(idioma)
  setApellido(apellido)
  setEmail(email)
  setTipo(tipo)
  setIdBrokFor(brokFor)
  toggle()
}

function editUsuario(event) {
  event.preventDefault();
  if(tipo == "Admin"){
    axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
        idioma,
        tipo,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
  }else if(tipo == "Broker"){
    axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
        idioma,
        tipo,
        brokers: idBrokFor,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
  }else if(tipo == "Forwarder"){
    axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
        idioma,
        tipo,
        forwarders: idBrokFor
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
  }
  
}

function Permisos (
  id, 
  nombre,
  apellido,
  menu_usuarios,
  usuarios_create,
  usuarios_permisos,
  menu_logs,
  menu_gastos,
  menuClientes,
  menuArticulos,
  menuNavieras,
  menuBrokers,
  menuForwarders,
  menuEmpresas,
  menuContenedores,
  contenedoresCreate,
  contenedoresEdit,
  contenedoresReporte,
  menuSurtidos,
  menuVentas,
  gastosCreate,
  gastosAutorizar,
  menuPagos,
  pagosCreate,
  estadoCuentaBrokers,
  estadoCuentaClientes,
  menuAbonos,
  abonosCreate,
  menuCatalogos,
  menuAdmin,
  menuReportes,
  ){
      setIdPermisos(id)
      setNombre(nombre)
      setApellido(apellido)
      setMenuUsuarios(menu_usuarios)
      setUsuariosCreate(usuarios_create)
      setUsuariosPermisos(usuarios_permisos)
      setMenuLogs(menu_logs)
      setMenuGastos(menu_gastos)
      setMenuClientes(menuClientes)
      setMenuArticulos(menuArticulos)
      setMenuNavieras(menuNavieras)
      setMenuBrokers(menuBrokers)
      setMenuForwarders(menuForwarders)
      setMenuEmpresas(menuEmpresas)
      setMenuContenedores(menuContenedores)
      setContenedoresCreate(contenedoresCreate)
      setContenedoresEdit(contenedoresEdit)
      setContenedoresReporte(contenedoresReporte)
      setMenuSurtidos(menuSurtidos)
      setMenuVentas(menuVentas)
      setGastosCreate(gastosCreate)
      setGastosAutorizar(gastosAutorizar)
      setMenuPagos(menuPagos)
      setPagosCreate(pagosCreate)
      setEstadoCuentaBrokers(estadoCuentaBrokers)
      setEstadoCuentaClientes(estadoCuentaClientes)
      setMenuAbonos(menuAbonos)
      setAbonosCreate(abonosCreate)
      setMenuCatalogos(menuCatalogos)
      setMenuAdmin(menuAdmin)
      setMenuReportes(menuReportes)
      togglePermisos()
}

function permisosUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idPermisos}`,
      {
        menu_usuarios,
        usuarios_create,
        usuarios_permisos,
        menu_logs,
        menu_gastos,
        menuClientes,
        menuArticulos,
        menuNavieras,
        menuBrokers,
        menuForwarders,
        menuEmpresas,
        menuContenedores,
        contenedoresCreate,
        contenedoresEdit,
        contenedoresReporte,
        menuSurtidos,
        menuVentas,
        gastosCreate,
        gastosAutorizar,
        menuPagos,
        pagosCreate,
        estadoCuentaBrokers,
        estadoCuentaClientes,
        menuAbonos,
        abonosCreate,
        menuCatalogos,
        menuAdmin,
        menuReportes,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      togglePermisos();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}


function jalaInfo(idUser) {
  setIdUser(idUser);
  togglePassword();
}

  return (
    <>
    <Header />
    <br />
    {user.menu_usuarios  ?(
        <div className="row">
        {
          <div className="col-9">
            <div className="card container">
              <div className="card-body">
                <h3 align="center">Usuarios</h3>
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>
                    {commentsData
                    .map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Don == false && p.email != "admin@sistemify.com") {
                      return (
                        <tr>
                          <td>{p.is_active}</td>
                          <td>{p.nombre} {p.apellido}</td>
                          <td>{p.tipo}</td>
                          <td>{p.brokFor}</td>
                          {/* <td>{p.idioma}</td> */}
                          <td>{p.email}</td>
                          <td>
                            {user.usuarios_create ? (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => {
                                  Edit(
                                    p._id,
                                    p.nombre,
                                    p.apellido,
                                    p.email,
                                    p.idioma,
                                    p.tipo,
                                    p.idBrokFor,
                                  );
                                }}
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            ) : (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            )}

                            {user.usuarios_create ? (
                              <Button
                                color="success"
                                id="Password"
                                size="sm"
                                onClick={(e) =>
                                  jalaInfo(
                                    p._id,
                                  )
                                }
                              >
                                <i class="fas fa-user-lock"></i>
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                id="Password"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-user-lock"></i>
                              </Button>
                            )}

                            {user.usuarios_permisos &&
                            user.is_active == "Si" ? (
                              <Button
                                color="success"
                                id="Permisos"
                                size="sm"
                                onClick={(e) => {
                                  Permisos(
                                    p._id,
                                    p.nombre,
                                    p.apellido,
                                    p.menu_usuarios,
                                    p.usuarios_create,
                                    p.usuarios_permisos,
                                    p.menu_logs,
                                    p.menu_gastos,
                                    p.menuClientes,
                                    p.menuArticulos,
                                    p.menuNavieras,
                                    p.menuBrokers,
                                    p.menuForwarders,
                                    p.menuEmpresas,
                                    p.menuContenedores,
                                    p.contenedoresCreate,
                                    p.contenedoresEdit,
                                    p.contenedoresReporte,
                                    p.menuSurtidos,
                                    p.menuVentas,
                                    p.gastosCreate,
                                    p.gastosAutorizar,
                                    p.menuPagos,
                                    p.pagosCreate,
                                    p.estadoCuentaBrokers,
                                    p.estadoCuentaClientes,
                                    p.menuAbonos,
                                    p.abonosCreate,
                                    p.menuCatalogos,
                                    p.menuAdmin,
                                    p.menuReportes
                                  );
                                }}
                              >
                                <i class="fas fa-key"></i>{" "}
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                id="Permisos"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-key"></i>{" "}
                              </Button>
                            )}
                            {user.usuarios_create ? (
                              <Baja
                                idStatus={p._id}
                                is_active={p.is_active}
                                URL_BAJA={process.env.REACT_APP_URL_USERS}
                              />
                            ) : undefined}
                          </td>
                        </tr>
                      );
                      // }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        }
 <div className="col-3">
          <div className="card">
            <div className="card-body">
              <Signup />
            </div>
          </div>
        </div>
        </div>
      ): undefined } 

<Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Editar Usuario {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">{t("Nombre")}</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Apellido</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={apellido}
            required
            onChange={(e) => {
              setApellido(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Label className="mr-sm-2">{t("Idioma")}</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={idioma}
            required
            onChange={(e) => {
              setIdioma(e.target.value);
            }}
          >
            <option value="es">Español</option>;
            <option value="en">English</option>;
          </Input>


          <Label>Tipo</Label>
              <Input
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                type="select"
                
              >
                <option value="">{("Selecciona")}</option>
                <option value="Admin">Admin</option>
                <option value="Broker">Broker</option>
                <option value="Forwarder">Forwarder</option>
                </Input>

                {tipo == "Broker" ? (
                    <>
                    <Label>Broker</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={idBrokFor}
                      required
                      onChange={(e) => {
                        setIdBrokFor(e.target.value);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {brokers
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                    </>
                ): undefined}


                  {tipo == "Forwarder" ? (
                    <>
                    <Label>Forwarder</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={idBrokFor}
                      required
                      onChange={(e) => {
                        setIdBrokFor(e.target.value);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {forwarders
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                    </>
                ): undefined}

        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}><h4>Permisos de {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
        <div className="container">

          <Row>
          <Col md={3}>
      <h5>CATALOGOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuCatalogos(event.currentTarget.checked)}
        checked={menuCatalogos}
      /><h5 className="subMenuTitulo">Menu Catalogos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuClientes(event.currentTarget.checked)}
        checked={menuClientes}
      /><h5 className="subMenuTitulo">Clientes</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuArticulos(event.currentTarget.checked)}
      checked={menuArticulos}
    /><h5 className="subMenuTitulo">Articulos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuNavieras(event.currentTarget.checked)}
        checked={menuNavieras}
      /><h5 className="subMenuTitulo">Navieras</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuForwarders(event.currentTarget.checked)}
      checked={menuForwarders}
     /><h5 className="subMenuTitulo">Forwarders</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuBrokers(event.currentTarget.checked)}
      checked={menuBrokers}
     /><h5 className="subMenuTitulo">Brokers</h5>
          <Input
          type="checkbox"
          onChange={(event) => setMenuEmpresas(event.currentTarget.checked)}
          checked={menuEmpresas}
        /><h5 className="subMenuTitulo">Empresas</h5>

      </Col>

      <Col md={3}>
      <h5>CONTENEDORES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuContenedores(event.currentTarget.checked)}
        checked={menuContenedores}
      /><h5 className="subMenuTitulo">Menu Contenedores</h5>
        <Input
        type="checkbox"
        onChange={(event) => setContenedoresCreate(event.currentTarget.checked)}
        checked={contenedoresCreate}
      /><h5 className="subMenuTitulo">Crear Contenedores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setContenedoresEdit(event.currentTarget.checked)}
        checked={contenedoresEdit}
      /><h5 className="subMenuTitulo">Editar Contenedores</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuSurtidos(event.currentTarget.checked)}
        checked={menuSurtidos}
      /><h5 className="subMenuTitulo">Surtidos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuVentas(event.currentTarget.checked)}
      checked={menuVentas}
     /><h5 className="subMenuTitulo">Ventas</h5>
      </Col>

      <Col md={3}>
      <h5>PAGOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuPagos(event.currentTarget.checked)}
        checked={menuPagos}
      /><h5 className="subMenuTitulo">Menu Pagos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setPagosCreate(event.currentTarget.checked)}
        checked={pagosCreate}
      /><h5 className="subMenuTitulo">Crear Pagos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setEstadoCuentaBrokers(event.currentTarget.checked)}
      checked={estadoCuentaBrokers}
     /><h5 className="subMenuTitulo">Estado Cuenta Brokers</h5>
      </Col>

      <Col md={3}>
      <h5>ADMIN</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAdmin(event.currentTarget.checked)}
        checked={menuAdmin}
      /><h5 className="subMenuTitulo">Menu Admin</h5>
      <Input
      type="checkbox"
      onChange={(event) => setMenuAbonos(event.currentTarget.checked)}
      checked={menuAbonos}
    /><h5 className="subMenuTitulo">Menu Abonos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setAbonosCreate(event.currentTarget.checked)}
        checked={abonosCreate}
      /><h5 className="subMenuTitulo">Crear Abonos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setEstadoCuentaClientes(event.currentTarget.checked)}
      checked={estadoCuentaClientes}
     /><h5 className="subMenuTitulo">Estado Cuenta Clientes</h5>
      </Col>
          </Row>

          <br />
          
          <Row>

          <Col md={3}>
      <h5>GASTOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuGastos(event.currentTarget.checked)}
        checked={menu_gastos}
      /><h5 className="subMenuTitulo">Menu Gastos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setGastosCreate(event.currentTarget.checked)}
        checked={gastosCreate}
      /><h5 className="subMenuTitulo">Crear Gastos</h5>
      <Input
      type="checkbox"
      onChange={(event) => setGastosAutorizar(event.currentTarget.checked)}
      checked={gastosAutorizar}
    /><h5 className="subMenuTitulo">Autorizar Gastos</h5>
      </Col>

    <Col md={3}>
    <h5>REPORTES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuReportes(event.currentTarget.checked)}
        checked={menuReportes}
      /><h5 className="subMenuTitulo">Menu Reportes</h5>
      <Input
      type="checkbox"
      onChange={(event) => setContenedoresReporte(event.currentTarget.checked)}
      checked={contenedoresReporte}
    /><h5 className="subMenuTitulo">Reporte Contenedores</h5>
      
      </Col>

      <Col md={3}>
    <h5>USUARIOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuUsuarios(event.currentTarget.checked)}
        checked={menu_usuarios}
      /><h5 className="subMenuTitulo">Menu Usuarios</h5>
      
      </Col>

      <Col md={3}>
      <h5>LOGS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuLogs(event.currentTarget.checked)}
        checked={menu_logs}
      /><h5 className="subMenuTitulo">Menu Logs</h5>
      </Col>
      


      </Row>
      </div>
        </ModalBody>
        <ModalFooter>
          <div className="container">
          <Button color="success" 
          onClick={permisosUsuario}
          >
            {t("Guardar")}
          </Button>
          </div>
        </ModalFooter>
      </Modal>


      <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <PasswordColaboradores idColaborador={idUser}/>
                  </ModalBody>
                </Modal>

{loader}
    </>
  );
}

export default ListadoUsuarios;
