import React, { useState, useContext, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";
import { Row, Input, Label, Col } from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function GraficaVentasMensual() {
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 365);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  const [t, i18n] = useTranslation("global");
  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  const [graficas, setGraficas] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [value, setValue] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useMemo(() => {
    axios
      .get(
        `${URL_GRAFICAS}/ventaMensual/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allGraficas = response.data;
        setGraficas(allGraficas);
        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });

    if (cargando) {
      Toast.fire({
        icon: "success",
        title: "Danos unos segundos....",
      });
    }

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin, cargando, selectedCliente]);


  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    let data = graficas;

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "Mes",
      })
    );
    xAxis.data.setAll(data);

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Venta",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Importe",
        categoryXField: "Mes",
        tooltip: am5.Tooltip.new(root, {
          labelText: "${valueY}",
        }),
      })
    );
    series1.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data
    });

    return () => {
      root.dispose();
    };
  }, [graficas]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container col-12">
        <h3 align="center">Venta Mensual</h3>
        <div className="container col-12">
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                  setCargando(true);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                  setCargando(true);
                }}
              />
            </Col>
          </Row>
        </div>
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      </div>
    </>
  );
}
export default GraficaVentasMensual;
