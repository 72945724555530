import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ListadoPendienteSurtir() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR = process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_contenedor, setNumeroContenedor] = useState("");

  const [fecha, setFecha] = useState(hoy);
  const [fechaEntregaReal, setFechaEntregaReal] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);

  const [selectedContenedor, setSelectedContenedor] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosCont, setArticulosCont] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [abrirSurtir, setAbrirSurtir] = useState(false);

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState(16);

  const [montoServicio, setMontoServicio] = useState(0);
  const [pendienteSurtir, setPendienteSurtir] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      descripcion:"",
      cantidad: 0,
      idArtCont: "",
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantCont: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CONTENEDORES}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                numero: a.idContenedor,
                activo: a.is_active,
                fecha: a.fecha,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                codigo: a.clientes[0].codigo,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                surtido: a.surtido,
                impuestos: a.impuestos,
                pendienteSurtir: a.pendienteSurtir,
                numeroReferencia: a.numeroReferencia,
                referenciaExtra: a.referenciaExtra,
                referencia: a.referencia,
              };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoContenedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoContenedores",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ListadoContenedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    codigo,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idPDFOC,
  ) {

    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;


        let allArticulosContenedor2 = allArticulosContenedor
          .map((a) => {
            return {
              articulo: a.articulos[0].name,
              idArticulo: a.articulos[0]._id,
              cantidadContenedor: a.cantidad,
              surtido: a.cantidadSurtido,
              pendiente_surtir: a.pendienteSurtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataContenedores = Object.values(allArticulosContenedor2);



        let totalContenedor = dataContenedores.map((c) => parseFloat(c.cantidadContenedor));
        let TP = totalContenedor.reduce((t, total, index) => t + total, 0);

        let totalSurtido = dataContenedores.map((c) => parseFloat(c.surtido));
        let TS = totalSurtido.reduce((t, total, index) => t + total, 0);

        let totalPendSurtir = dataContenedores.map((c) =>
          parseFloat(c.pendiente_surtir)
        );
        let TPS = totalPendSurtir.reduce((t, total, index) => t + total, 0);

        const data = dataContenedores
          .sort((a, b) => (a.articulo < b.articulo ? 1 : -1))
          .map((a) => {
            return [
              a.articulo,
              new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
            ];
          })
          .sort((a, b) => (a.idArticulo > b.idArticulo ? 1 : -1));
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 15, 15);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`No. Contenedor:`, 35, 20);
        doc.text(`${numero}`, 68, 20);


        doc.autoTable({
          head: [["Articulo",  "Pend Surtir"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 30,
          foot: [
            [
              "Totales",
              new Intl.NumberFormat("en-US").format(TPS),
            ],
          ],
          showFoot: "lastPage",
        });
        
        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Contenedor-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

async  function excelOC(
    idPDFOC,
    clientes,
    ) {

      await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;


        let allArticulosContenedor2 = allArticulosContenedor
          .map((a) => {
            return {
              articulo: a.articulos[0].name ,
              idArticulo: a.articulos[0]._id,
              cantidadContenedor: a.cantidad,
              surtido: a.cantidadSurtido,
              pendiente_surtir: a.pendienteSurtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataContenedores = Object.values(allArticulosContenedor2);


    const dataExcel = dataContenedores.map((a) => {
        return {
          Cliente: clientes,
          Articulo: a.articulo,
          PendSurtir: a.pendiente_surtir,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "DesgloceContenedor";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "DesgloceContenedor",
        sheetFilter: [
        "Cliente",
        "Articulo", 
        "PendSurtir", 
        ],
        sheetHeader: [
        "Cliente",
        "Articulo", 
        "PendSurtir", 
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroReferencia", sortable: true },
    { name: `${t("Ref. Extra")}`, field: "referenciaExtra", sortable: true },
    { name: `${t("Contenedor")}`, field: "referencia", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    { name: `${t("PDF")}`, field: "pdf", sortable: false },
    { name: `${t("Surtir")}`, field: "surtir", sortable: false },
    // { name: `${t("Surtido")}`, field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroReferencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.referenciaExtra.toLowerCase().includes(search.toLowerCase()) ||
          comment.referencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    if(selectedEmpresa != "" && montoServicio != 0){
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    // let iva = 0;
    // let TT = 0;

    // let STotal = inputFields.map((c) => parseFloat(c.total));
    // let ST = STotal.reduce((t, total, index) => t + total, 0);

    // if (impuestos != 0) {
    //   iva = (impuestos * ST) / 100;
    // } else {
    //   iva = 0;
    // }
    // TT = (impuestos * ST) / 100 + ST

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            contenedores: selectedContenedor,
            empresas: selectedEmpresa,
            observaciones,
            user: user.id,
            clientes: cliente,
            totalPiezas: total,
            totalDinero: totalGeneral,
            // totalDinero: parseFloat(TT).toFixed(2),
            saldo: montoServicio,
            abonos:0,
            montoServicio,
            subTotal: subtotal,
            // subTotal: parseFloat(ST).toFixed(2),
            iva: iva,
            // iva: parseFloat(iva).toFixed(2),
            impuestos,
            is_active:"Si",
            pendienteSurtir,
            fechaEntregaReal
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    surtidos: data.data._id,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    contenedores: selectedContenedor,
                    articulosContenedor: a.idArtCont,
                    clientes: cliente,
                    fecha,
                    precio:a.precio,
                    total:a.total
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_contenedor} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_contenedor} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }}else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Faltan datos, favor de revisar!",
      });
    }
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        descripcion:"",
        cantidad: 0,
        idArtCont: "",
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantCont: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    // let importes = values.map((c) => parseFloat(c.total));
    // let TG = importes.reduce((t, total, index) => t + total, 0);
    // setTotalGeneral(TG);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (
          (i.pendSurtir >= event.target.value) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu contenedor!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputTotal = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i[event.target.name] = event.target.value;
          i.precio = (parseFloat(event.target.value) / parseFloat(i.cantidad)).toFixed(2);
        
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };
 


  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    // let importes = inputFields.map((c) => parseFloat(c.total));
    // let TG = importes.reduce((t, total, index) => t + total, 0);
    // setTotalGeneral(TG);
  }



  async function seleccionarContenedor(
    id,
    numero,
    idClientes,
    clientes,
    pendienteSurtir
  ) {
    setSelectedContenedor(id);
    setNumeroContenedor(numero);
    setCliente(idClientes);
    setClientesName(clientes);
    setPendienteSurtir(pendienteSurtir)

    axios
    .get(`${URL_ARTICULOS_CONTENEDOR}/contenedorSurtir/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosCont = res.data;
      setArticulosCont(allArticulosCont);

      let arrayInputFields = allArticulosCont
        .map((a) => {
          return {
            id: uuidv4(),
            idArtCont: a._id,
            articulos: a.articulos[0]._id,
            descripcion:a.descripcion,
            cantidad: a.pendienteSurtir,
            precio: a.precio,
            total: a.pendienteSurtir * a.precio,
            pendSurtir: a.pendienteSurtir,
            articuloNombre: a.articulos[0].name,
            cantCont: a.cantidad,
          };
        })

      let dataIF = Object.values(arrayInputFields);

      setInputFields(dataIF);

      let cant = dataIF.map((c) => parseFloat(c.cantidad));
      let TC = cant.reduce((t, total, index) => t + total, 0);
      setTotal(TC);


    // let importes = dataIF.map((c) => parseFloat(c.total));
    // let TG = importes.reduce((t, total, index) => t + total, 0);
    // setTotalGeneral(TG);
      
    setAbrirSurtir(true);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuSurtidos ? (
        <div className="card col-12">
          {!abrirSurtir ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuContenedores"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                     {t("Regresar")}
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>{t("Enviar Listado Pendiente Surtir")}</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                   {t("Enviar")}
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">{t("Pendientes de Surtir")}</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>{t("Clientes")}</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>

               
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.numeroReferencia}</td>
                          <td>{a.referenciaExtra}</td>
                          <td>{a.referencia}</td>
                          <td>{a.clientes}</td>
                          <td>
                            {user.menuSurtidos ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.idClientes,
                                      a.clientes,
                                      a.codigo,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a._id,
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(
                                      a._id,
                                      a.clientes,
                                    )
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>

                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarContenedor(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.pendienteSurtir
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          </td>
                          {/* <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_CONTENEDORES}
                              hijo={`${URL_ARTICULOS_CONTENEDOR}/baja`}
                            />
                          </td> */}
                        </tr>
                      );
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Contenedor {numero_contenedor}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>{t("Fecha")}</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Empresa</Label>
                    <Input
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(e) => {
                        setSelectedEmpresa(e.target.value);
                      }}
                      >
                      <option value="0">{t("Selecciona")}</option>
                      {empresas
                        .sort((a, b) =>
                          a.name > b.name ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.name}</option>
                          );
                        })}
                        </Input>
                  </Col>

                  {/* <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      placeholder="Impuestos"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col> */}
                  <Col md={2}>
                    <Label>Monto de Servicio</Label>
                    <Input
                      type="number"
                      placeholder="monto"
                      value={montoServicio}
                      required
                      onChange={(e) => {
                        setMontoServicio(e.target.value);
                      }}
                    />
                  </Col>


                  <Col md={2}>
                    <Label>{t("Fecha Entrega")}</Label>
                    <Input
                      type="date"
                      placeholder="FechaEntregaReal"
                      value={fechaEntregaReal}
                      required
                      onChange={(e) => {
                        setFechaEntregaReal(e.target.value);
                      }}
                    />
                  </Col>
</Row>
<Row>
                    <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>

                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">{t("Descripcion")}</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">{t("Precio")}</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Cont</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">{t("Cantidad")}</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">{t("Total")}</Label>
                  </Col>
                </Row>

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          disabled
                        >
                          <option value="">Selecciona un Articulo</option>
                          {articulosCont
                            .sort((a, b) =>
                              a.articulos[0].name > b.articulos[0].name ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos[0]._id}>
                                  {a.articulos[0].name}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="descripcion"
                          type="text"
                          placeholder="descripcion"
                          value={inputField.descripcion}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantCont"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Cont"
                          value={inputField.cantCont}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>



                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="total"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="total"
                          value={inputField.total}
                          disabled
                          onChange={(event) =>
                            handleChangeInputTotal(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTAL {total} Pzas. / {"$" + new Intl.NumberFormat("en-US").format(totalGeneral)}
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    {t("Guardar")}
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    {t("Guardar")}
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                   {t("Regresar")}
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>{t("Progreso")}</h4>
            </ModalHeader>
            <ModalBody>
             <h4> {t("Este proceso puede tardar varios segundos.")}
              <br />
              {t("Por favor no cierre ni refresque su navegador.")}
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteSurtir;
