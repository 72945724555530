import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesGastos() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menu_gastos ?(
      <div className="container">
        <br />
        <br />
        <Row >

          
        <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/Gastos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Costo Importacion")}
            </Button>
            ): undefined }
            </Col>

            <Col md={4} align="center">
            {user.menuPagos && user.tipo == "Admin" ?(
            <Button href="/ListadoAbonosGastos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Pagos")}
            </Button>
            ): undefined }
            </Col>
        <Col md={4} align="center">
          {user.menu_gastos && user.tipo == "Admin"?(
            <Button href="/ConceptosGastos" className="botonesMenu" color="success">
              <i class="far fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Conceptos")}
            </Button>
          ): undefined }
          </Col>

            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.estadoCuentaBrokers && user.tipo == "Admin" ?(
            <Button href="/EstadoCuentaCXP" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Estado de Cuenta")}
            </Button>
            ): undefined }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesGastos;
