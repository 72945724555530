import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesAdmin() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menuAdmin ?(
      <div className="container">
        <br />
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuAbonos ?(
            <Button href="/ListadoAbonos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              {t("Abonos")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          {t("Abonos")}
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.estadoCuentaClientes ?(
            <Button href="/EstadoCuenta" className="botonesMenu" color="success">
              <i class="fas fa-list-alt fa-7x"></i>
              <br />
              <br />
              {t("Estado de Cuenta")}
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-list-alt fa-7x"></i>
          <br />
          <br />
          {t("Estado de Cuenta")}
        </Button> }
          </Col>

        
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesAdmin;
