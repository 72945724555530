import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import NavierasCreate from "./NavierasCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ListadoNavieras() {
  const { user } = useContext(AuthContext);
  const URL_NAVIERAS = process.env.REACT_APP_URL_NAVIERAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [navieras, setNavieras] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);
  const [t, i18n] = useTranslation("global");

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_NAVIERAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNavieras = response.data;
        setNavieras(allNavieras);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editNaviera(event) {
    event.preventDefault();
    const URL_NAVIERAS_EDIT = `${process.env.REACT_APP_URL_NAVIERAS}/${idEdit}`;
    axios
      .patch(
        URL_NAVIERAS_EDIT,
        {
          name
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Naviera',
            detalle: name,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name) {
        setName(name);
        setIdEdit(idEdit);
        toggle();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menuNavieras ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">{t("Navieras")}</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">{t("Nombre")}</th>
                          <th className="tituloTabla">{t("Editar")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {navieras
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(a._id, a.name)}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_NAVIERAS
                                      }
                                    />
                              
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <NavierasCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Editar")} {t("Naviera")}</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">{t("Nombre")}</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editNaviera}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoNavieras;
