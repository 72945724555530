import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Signup = function () {
  const { user } = useContext(AuthContext);
  const URL_SIGNUP = process.env.REACT_APP_URL_USERS;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_FORWARDERS = process.env.REACT_APP_URL_FORWARDERS;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [text, setText] = useState(false);
  const [tipo, setTipo] = useState("");
  const [telefono, setTelefono] = useState();
  const [idioma, setIdioma] = useState();
  const [brokers, setBrokers] = useState([]);
  const [forwarders, setForwarders] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedForwarder, setSelectedForwarder] = useState("");
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    axios
    .get(`${URL_BROKERS}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allBrokers = res.data;
        setBrokers(allBrokers);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(`${URL_FORWARDERS}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allForwarders = res.data;
        setForwarders(allForwarders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const handleForm = (event) => {
    event.preventDefault();
    if(tipo == "Admin"){
      axios
      .post(
       URL_SIGNUP,
        {
          nombre: firstName,
          apellido: lastName,
          email,
          password,
          tipo,
          telefono,
          idioma
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }else if(tipo == "Broker"){
      axios
      .post(
       URL_SIGNUP,
        {
          nombre: firstName,
          apellido: lastName,
          brokers: selectedBroker,
          email,
          password,
          tipo,
          telefono, 
          idioma
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }else if(tipo == "Forwarder"){
      axios
      .post(
       URL_SIGNUP,
        {
          nombre: firstName,
          apellido: lastName,
          forwarders: selectedForwarder,
          email,
          password,
          tipo,
          telefono,
          idioma
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }
    
  };



  return (
    <>
      <br />
      <br />
        <div className="card container">
          <h3 align="center">{t("Nuevo")} {t("Usuario")}</h3>
          <Form className="container" onSubmit={handleForm}>
          <Label>Tipo</Label>
              <Input
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                type="select"
                
              >
                <option value="">{("Selecciona")}</option>
                <option value="Admin">Admin</option>
                <option value="Broker">Broker</option>
                <option value="Forwarder">Forwarder</option>
                </Input>

                {tipo == "Broker" ? (
                    <>
                    <Label>Broker</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedBroker}
                      required
                      onChange={(e) => {
                        setSelectedBroker(e.target.value);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {brokers
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                    </>
                ): undefined}


                  {tipo == "Forwarder" ? (
                    <>
                    <Label>Forwarder</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedForwarder}
                      required
                      onChange={(e) => {
                        setSelectedForwarder(e.target.value);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {forwarders
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                    </>
                ): undefined}


              <Label>Nombre</Label>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                
              />
              <Label>Apellido</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="lastName"
                placeholder="Apellido"
              />
              <Label>Email</Label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                placeholder="Email"
              />
              <Label>Telefono</Label>
              <Input
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                type="number"
                id="firstName"
                
              />

          <Label className="mr-sm-2">{t("Idioma")}</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={idioma}
            required
            onChange={(e) => {
              setIdioma(e.target.value);
            }}
          >
            <option value="">Selecciona</option>;
            <option value="es">Español</option>;
            <option value="en">English</option>;
          </Input>
              <Label>Contraseña</Label>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Contraseña"
              />
            {user.usuarios_create?(
            <Button type="submit" className="btn btn-success">
            Dar de Alta
          </Button>
          ): (
            <Button type="submit" className="btn btn-success" disabled>
            Dar de Alta
          </Button>
          ) }

          </Form>
      </div>
    </>
  );
};

export default Signup;
