import React, { useState } from 'react';
import { Row, Input, Label, Col } from "reactstrap";
import axios from 'axios';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function WhatsApp() {
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const [number, setNumber] = useState('5547337640');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState('');
  const [caption, setCaption] = useState('');
  
  const sendMessage = async () => {
    try {
      await axios.post(`${URL_WHATSAPP}SendMsg`, {
        number: number,
        message: message
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }).then((response)=>{

      if(response.data.message == "Enviado"){
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu mensaje ha sido enviado",
          showConfirmButton: false,
          timer: 2000,
        });
        // setNumber("")
        // setMessage("")
      }
      })
      .catch((err) => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    }
  };

  const sendFile = async () => {
    try {
      await axios.post(`${URL_WHATSAPP}SendPDF`, {
        number,
        file,
        caption
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }).then((response)=>{

      if(response.data.message == "Enviado"){
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu mensaje ha sido enviado",
          showConfirmButton: false,
          timer: 2000,
        });
        // setNumber("")
        // setFile("")
      }
      })
      .catch((err) => {
        console.log(err);
      });

    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    }
  };


  return (
    <>
    <div>
      <h1>Enviar Mensaje de WhatsApp</h1>
      <div>
        <label>Número de Teléfono:</label>
        <input type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
      </div>
      <div>
        <label>Mensaje:</label>
        <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
      </div>
    <br />

      <button onClick={sendMessage}>Enviar Mensaje</button>
      <br />
    <br />


    </div>

    <div>
      <h1>Enviar File</h1>
      <div>
        <label>Número de Teléfono:</label>
        <input type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
      </div>
      <div>
        <label>Mensaje:</label>
        <input type="text" value={file} onChange={(e) => setFile(e.target.value)} />
      </div>
      <div>
        <label>Caption:</label>
        <input type="text" value={caption} onChange={(e) => setCaption(e.target.value)} />
      </div>
    <br />

      <button onClick={sendFile}>Enviar File</button>
      <br />
    <br />


    </div>
    </>
  );
}

export default WhatsApp;
