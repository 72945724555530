import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

function ListadoContenedores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR =
    process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_FORWARDERS = process.env.REACT_APP_URL_FORWARDERS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;
  const URL_UPMEDIA_PACKING = process.env.REACT_APP_URL_UPMEDIA_PACKING;
  const URL_DELMEDIA = process.env.REACT_APP_URL_DELMEDIA;
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [t, i18n] = useTranslation("global");
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 91);

  let comp30 = new Date();
  comp30.setDate(comp30.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [forwarders, setForwarders] = useState([]);
  const [selectedForwarder, setSelectedForwarder] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedSurtido, setSelectedSurtido] = useState("");
  // const [selectedEnMar, setSelectedEnMar] = useState("");
  const [selectedPrevio, setSelectedPrevio] = useState("");

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [totalGeneral, setTotalGeneral] = useState("");
  const [totalCantidad, setTotalCantidad] = useState("");
  const [numeroContenedor, setNumeroContenedor] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedBrokerEdit, setSelectedBrokerEdit] = useState("");
  const [selectedForwarderEdit, setSelectedForwarderEdit] = useState("");

  const [flete, setFlete] = useState("");
  const [incrementable, setIncrementable] = useState("");
  const [numeroReferencia, setNumeroReferencia] = useState("");
  const [referenciaExtra, setReferenciaExtra] = useState("");
  const [naviera, setNaviera] = useState("");
  const [referencia, setReferencia] = useState("");
  const [fechaETD, setFechaETD] = useState("");
  const [fechaETAEstimada, setFechaETAEstimada] = useState("");
  const [fechaETAReal, setFechaETAReal] = useState("");
  const [fechaLlegada, setFechaLlegada] = useState("");
  const [fechaLiberacion, setFechaLiberacion] = useState("");
  const [fechaEntregaEstimada, setFechaEntregaEstimada] = useState("");
  const [fechaEntregaReal, setFechaEntregaReal] = useState("");
  const [aduana, setAduana] = useState("");
  const [selectedNaviera, setSelectedNaviera] = useState("");
  const [navierasFiltro, setNavierasFiltro] = useState([]);

  const [photo, setPhoto] = useState([]);
  const [idContenedor, setIdContenedor] = useState();
  const [file, setFile] = useState([]);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  const [modalSubirFoto, setModalSubirFoto] = useState(false);
  const toggleSubirFoto = () => setModalSubirFoto(!modalSubirFoto);
  const [modalSubirFotoPacking, setModalSubirFotoPacking] = useState(false);
  const toggleSubirFotoPacking = () => setModalSubirFotoPacking(!modalSubirFotoPacking);
  const [modalFotoPacking, setModalFotoPacking] = useState(false);
  const toggleFotoPacking = () => setModalFotoPacking(!modalFotoPacking);
  const [photoFormat, setPhotoFormat] = useState("");
  const [PLPDF, setPLPDF] = useState("");

  const [articulosContenedor, setArticulosContenedor] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [totalGeneral_edit, setTotalGeneralEdit] = useState(0);
  const [totalCantidad_edit, setTotalCantidadEdit] = useState(0);

  const [totalCaptura, setTotalCaptura] = useState(1);

  // const [inputFields, setInputFields] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [inputFieldsFlete, setInputFieldsFlete] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalFlete, setModalFlete] = useState(false);
  const toggleFlete = () => setModalFlete(!modalFlete);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;
  useMemo(() => {
    if (user.tipo == "Forwarder") {
      setSelectedForwarder(user.forwarders);
    }

    if (user.tipo == "Broker") {
      setSelectedBroker(user.brokers);
    }

    axios
      .get(
        `${URL_CONTENEDORES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idContenedor,
              activo: a.is_active,
              fecha: a.fecha,
              observaciones: a.observaciones,
              totalGeneral: a.totalGeneral,
              naviera: a.naviera,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              brokers: a.brokers[0].name,
              idBrokers: a.brokers[0]._id,
              forwarders: a.forwarders[0].name,
              idForwarders: a.forwarders[0]._id,
              totalCantidad: a.totalCantidad,
              totalSurtido: a.totalSurtido,
              pendienteSurtir: a.pendienteSurtir,
              numeroReferencia: a.numeroReferencia,
              referenciaExtra: a.referenciaExtra,
              referencia: a.referencia,
              etd: a.etd,
              etaEstimada: a.etaEstimada,
              etaReal: a.etaReal,
              bl: a.bl,
              pl: a.pl,
              totalGastos: a.totalGastos,
              fechaLlegada: a.fechaLlegada,
              fechaLiberacion: a.fechaLiberacion,
              fechaEntregaEstimada: a.fechaEntregaEstimada,
              fechaEntregaReal: a.fechaEntregaReal,
              aduana: a.aduana,
              // enMar: a.enMar,
              surtido: a.surtido,
              previo: a.previo,
              packing: a.packing,
              flete: a.flete,
              incrementable: a.incrementable,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);

        var items = dataFinal.map((a) => {
          return a.naviera;
        });

        const unicos = [];
        for (var i = 0; i < items.length; i++) {
          const elemento = items[i];

          if (!unicos.includes(items[i])) {
            unicos.push(elemento);
          }
        }
        setNavierasFiltro(unicos);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_BROKERS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBrokers = response.data;
        setBrokers(allBrokers);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_FORWARDERS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allForwarders = response.data;
        setForwarders(allForwarders);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin, selectedForwarder]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.naviera)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referenciaExtra,
          a.referencia,
          a.naviera,
          a.clientes,
          a.brokers,
          a.forwarders,
          // a.observaciones,
          // a.enMar,
          a.surtido,
          a.flete,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Referencia",
          "Ref Extra",
          "Contenedor",
          "Naviera",
          "Cliente",
          "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Surtido",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.naviera)
      ) {
        return {
          Id: a._id,
          Numero: a.numero,
          Fecha: a.fecha,
          Referencia: a.numeroReferencia,
          // RefExtra: a.referenciaExtra,
          Contenedor: a.referencia,
          ETD: a.etd,
          ETAEst: a.etaEstimada,
          ETAReal: a.etaReal,
          FechaEntrega: a.fechaEntregaReal,
          Naviera: a.naviera,
          Cliente: a.clientes,
          Broker: a.brokers,
          Forwarder: a.forwarders,
          // Observaciones: a.observaciones,
          // Mar: a.enMar,
          Surtido: a.surtido,
          Flete: a.flete,
          Cantidad: a.totalCantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoContenedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoContenedores",
        sheetFilter: [
          "Id",
          "Numero",
          "Fecha",
          "Referencia",
          // "RefExtra",
          "Contenedor",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaEntrega",
          "Naviera",
          "Cliente",
          "Broker",
          "Forwarder",
          "Observaciones",
          // "Mar",
          "Surtido",
          "Flete",
          "Cantidad",
        ],
        sheetHeader: [
          "Id",
          "Numero",
          "Fecha",
          "Referencia",
          // "RefExtra",
          "Contenedor",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaEntrega",
          "Naviera",
          "Cliente",
          "Broker",
          "Forwarder",
          "Observaciones",
          // "Mar",
          "Surtido",
          "Flete",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedNaviera == 0 || selectedNaviera == a.naviera)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referenciaExtra,
          a.referencia,
          a.naviera,
          a.clientes,
          a.brokers,
          a.forwarders,
          // a.observaciones,
          // a.enMar,
          a.surtido,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Referencia",
          "Ref Extra",
          "Contenedor",
          "Naviera",
          "Cliente",
          "Broker",
          "Forwarder",
          // "Observaciones",
          // "Mar",
          "Surtido",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ListadoContenedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

      function importarArchivo(event){
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];
   
    if (name === "file") {
  
      let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
  
          workbook.SheetNames.forEach(function (sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
            hojas.push({
              data: XL_row_object,
              sheetName,
            });
          });
  
          let A1 = hojas[0].data
  
          let TC = totalCaptura
          A1.map((a)=>{
             TC = TC + 1
            setTotalCaptura(TC)
          inputFieldsFlete.push({
            id: uuidv4(),
            idCont: a.Id,
            flete: a.Flete
          })
          })
  
          // ActualizaTotales()
        }
    }
  }
  console.log("hola", inputFieldsFlete)

  function jalaInfo(
    fecha,
    idClientes,
    idBrokers,
    idForwarders,
    totalCantidad,
    totalGeneral,
    observaciones,
    numeroContenedor,
    numeroReferencia,
    referenciaExtra,
    flete,
    incrementable,
    naviera,
    referencia,
    etd,
    etaEstimada,
    etaReal,
    fechaLlegada,
    fechaLiberacion,
    fechaEntregaEstimada,
    fechaEntregaReal,
    aduana,
    idEdit
  ) {
    setFecha(fecha);
    setSelectedClienteEdit(idClientes);
    setSelectedBrokerEdit(idBrokers);
    setSelectedForwarderEdit(idForwarders);
    setTotalCantidad(totalCantidad);
    setTotalGeneral(totalGeneral);
    setObservaciones(observaciones);
    setNumeroContenedor(numeroContenedor);
    setNumeroReferencia(numeroReferencia);
    setFlete(flete);
    setIncrementable(incrementable);
    setReferenciaExtra(referenciaExtra);
    setNaviera(naviera);
    setReferencia(referencia);
    setFechaETD(etd);
    setFechaETAEstimada(etaEstimada);
    setFechaETAReal(etaReal);
    setFechaLlegada(fechaLlegada);
    setFechaLiberacion(fechaLiberacion);
    setFechaEntregaEstimada(fechaEntregaEstimada);
    setFechaEntregaReal(fechaEntregaReal);
    setAduana(aduana);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    clientes,
    brokers,
    forwarders,
    totalCantidad,
    totalGeneral,
    observaciones,
    numero,
    naviera,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].name,
              descripcion: a.descripcion,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              pendienteSurtir: a.pendienteSurtir,
              cantidadSurtido: a.cantidadSurtido,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = inputFields.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.descripcion,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Contenedor # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Naviera ${naviera}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(totalCantidad)}`,
          20,
          35
        );
        doc.autoTable({
          head: [["Articulo", "Descripcion", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          startY: 40,
          foot: [
            [
              "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(totalGeneral),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Contenedor-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editContenedores(event) {
    event.preventDefault();
    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;

    axios
      .patch(
        URL_CONTENEDORES_EDIT,
        {
          fecha,
          clientes: selectedClienteEdit,
          brokers: selectedBrokerEdit,
          forwarders: selectedForwarderEdit,
          observaciones,
          numeroReferencia,
          flete,
          incrementable,
          referenciaExtra,
          naviera,
          referencia,
          etd: fechaETD,
          etaEstimada: fechaETAEstimada,
          etaReal: fechaETAReal,
          fechaLlegada: fechaLlegada,
          fechaLiberacion: fechaLiberacion,
          fechaEntregaEstimada: fechaEntregaEstimada,
          fechaEntregaReal: fechaEntregaReal,
          aduana,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Contenedor",
            detalle: `${numeroContenedor} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function guardaFlete(event) {
    event.preventDefault();

    let totalFlete = inputFieldsFlete.length



    inputFieldsFlete.map((a) => {
      axios
        .patch(
          `${URL_CONTENEDORES}/${a.idCont}`,
          {
            flete: a.flete
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        )
        .then(() => {
          totalFlete = totalFlete - 1;
          if (totalFlete == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Editar Flete",
                  detalle: `Subir Fletes`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    });
  }

  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroReferencia", sortable: true},
    { name: `${t("Referencia Extra")}`, field: "referenciaExtra", sortable: true },
    { name: `${t("Contenedor")}`, field: "referencia", sortable: true },
    { name: `${t("Naviera")}`, field: "naviera", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    { name: `${t("Broker")}`, field: "brokers", sortable: true },
    { name: `${t("Forwarder")}`, field: "forwarders", sortable: true },
    { name: `${t("ETD")}`, field: "etd", sortable: true },
    { name: `${t("ETA")}`, field: "etaEstimada", sortable: true },
    { name: `${t("Cantidad")}`, field: "totalCantidad", sortable: true },
    { name: `${t("Flete")}`, field: "flete", sortable: true },
    { name: `${t("Incrementable")}`, field: "incrementable", sortable: true },
    // { name: `${t("Surtido")}`, field: "totalSurtido", sortable: true },
    // { name: `${t("Pend Surtir")}`, field: "pendienteSurtir", sortable: true },
    // { name: `${t("Total")}`, field: "totalGeneral", sortable: true },
    // { name: `${t("En Mar")}`, field: "enMar", sortable: false },
    { name: `${t("Entregado")}`, field: "surtido", sortable: false },
    { name: `${t("Fecha Entrega")}`, field: "fechaEntregaReal", sortable: true },
    { name: `${t("Detalle Piezas")}`, field: "PDF", sortable: false },
    { name: `${t("Previo")}`, field: "previo", sortable: false },
    { name: `${t("PL")}`, field: "pl", sortable: false },
    { name: `${t("Editar")}`, field: "editar", sortable: false },
    { name: `${t("Cancelar")}`, field: "cancelar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numeroReferencia
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.referenciaExtra.toLowerCase().includes(search.toLowerCase()) ||
          comment.referencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.naviera.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.brokers.toLowerCase().includes(search.toLowerCase()) ||
          comment.forwarders.toLowerCase().includes(search.toLowerCase()) ||
          // comment.enMar.toLowerCase().includes(search.toLowerCase()) ||
          comment.surtido.toLowerCase().includes(search.toLowerCase()) ||
          comment.incrementable.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.totalGeneral.toString().includes(search) ||
          comment.flete.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }

    if (selectedBroker) {
      computedComments = computedComments.filter((e) =>
        e.idBrokers.includes(selectedBroker)
      );
    }

    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedForwarder) {
      computedComments = computedComments.filter((e) =>
        e.idForwarders.includes(selectedForwarder)
      );
    }

    if (selectedNaviera) {
      computedComments = computedComments.filter((e) =>
        e.naviera.includes(selectedNaviera)
      );
    }

    if (selectedSurtido) {
      computedComments = computedComments.filter((e) =>
        e.surtido.includes(selectedSurtido)
      );
    }
    // if (selectedEnMar) {
    //   computedComments = computedComments.filter((e) =>
    //     e.enMar.includes(selectedEnMar)
    //   );
    // }

    if (selectedPrevio) {
      computedComments = computedComments.filter((e) =>
        e.previo.includes(selectedPrevio)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "totalSurtido" &&
      sorting.field != "pendienteSurtir" &&
      sorting.field != "totalGeneral" &&
      sorting.field != "flete" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "totalSurtido" ||
        sorting.field != "pendienteSurtir" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "flete" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "totalSurtido" ||
        sorting.field != "pendienteSurtir" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "flete" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedBroker,
    selectedCliente,
    selectedForwarder,
    selectedNaviera,
    selectedSurtido,
    // selectedEnMar,
    selectedPrevio,
  ]);

  function EditArticulos(idOC, numeroContenedor) {
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        descripcion: "",
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroContenedor(numeroContenedor);
    axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              descripcion: a.descripcion,
              precio: a.precio,
              total: a.total,
              pendienteSurtir: a.pendienteSurtir,
              cantidadSurtido: a.cantidadSurtido,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosContenedor(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        let PR =
          parseFloat(i.pendienteSurtir) -
          parseFloat(i.cantidad) +
          parseFloat(event.target.value);
        i.pendienteSurtir = PR;
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputExistentesArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputExistentesArticulo = (id, idArticulo) => {
    const newArticulosContenedor = articulosContenedor.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosContenedor(newArticulosContenedor);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        cantidad: 0,
        precio: ultimo.precio,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = values.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputArticulo = (id, idArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidadExistentes = articulosContenedor.map((c) =>
      parseFloat(c.cantidad)
    );
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosContenedor.map((c) =>
      parseFloat(c.total)
    );
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const saveContenedor = async (event) => {
    event.preventDefault();
    toggleProgreso();

    let totalArticulos = inputFields.length + articulosContenedor.length;

    const URL_CONTENEDORES_EDIT = `${process.env.REACT_APP_URL_CONTENEDORES}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_CONTENEDORES_EDIT,
          {
            totalGeneral: totalGeneral_edit,
            totalCantidad: totalCantidad_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_CONTENEDOR,
                  {
                    contenedores: idEdit,
                    articulos: a.articulos,
                    descripcion: a.descripcion,
                    cantidad: parseFloat(a.cantidad),
                    pendienteSurtir: parseFloat(a.pendienteSurtir),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Contenedor",
                          detalle: `${numeroContenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Contenedor",
                      detalle: `${numeroContenedor}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          articulosContenedor.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_CONTENEDOR}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                  pendienteSurtir: parseFloat(a.pendienteSurtir),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Contenedor",
                        detalle: `${numeroContenedor}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // function marcar(id) {
  //   Swal.fire({
  //     title: "Estas seguro?",
  //     text: "Se va a marcar!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, marcar!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .patch(
  //           `${URL_CONTENEDORES}/${id}`,
  //           {
  //             enMar: "Si",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "Se Actualizo",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  // function desMarcar(id) {
  //   Swal.fire({
  //     title: "Estas seguro?",
  //     text: "Se quitara la facturacion!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, quitar!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .patch(
  //           `${URL_CONTENEDORES}/${id}`,
  //           {
  //             enMar: "No",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "Se actualizo",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  function cancelarContenedor(idContenedor) {
    Swal.fire({
      title: `${t("Estas seguro?")}`,
      text: `${t("Se cancelara el Contenedor!")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("Si, Cancelar!")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_CONTENEDORES}Cancel/${idContenedor}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }
function guardaFotos() {
  const formData = new FormData();
  formData.append("file", file); // El archivo puede ser imagen, PDF, o Excel
  formData.append("contenedor", idContenedor);

  axios
    .post(URL_UPMEDIA, formData, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        "Content-Type": "multipart/form-data", // Asegura que el tipo de contenido sea correcto
      },
      params: {
        resource_type: "raw", // Para Cloudinary, permite la subida de archivos que no son imágenes
      },
    })
    .then((response) => {
      axios
        .patch(
          `${URL_CONTENEDORES}/${idContenedor}`,
          {
            previo: "Si",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          axios
            .post(
              URL_LOGS,
              {
                tipo: "Contenedor",
                detalle: `Subir Foto`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              Swal.fire("Good job!", "Actualizado con éxito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });

  setFile();
  setPhoto([]);
}


  function borrarFoto(id) {

    axios.post(`${URL_DELMEDIA}/${id}`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then(() => {

      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    // setFile();
    // setPhoto([]);
  }
  function jalaFoto(idCont) {
    setPhoto([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG_CONTENEDORES}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        // console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContenedor(idCont);


    toggleFoto();
  }

  // Foto Packing
  function guardaFotosPacking() {
    file.map((a)=>{
    const formData = new FormData();
    formData.append("file", a); // El archivo puede ser imagen, PDF, o Excel
    formData.append("contenedor", idContenedor);
  
    axios
      .post(URL_UPMEDIA_PACKING, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          "Content-Type": "multipart/form-data", // Asegura que el tipo de contenido sea correcto
        },
        params: {
          resource_type: "raw", // Para Cloudinary, permite la subida de archivos que no son imágenes
        },
      })
      .then((response) => {
        axios
          .patch(
            `${URL_CONTENEDORES}/${idContenedor}`,
            {
              packing: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Contenedor",
                  detalle: `Subir Foto`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then((response) => {
                Swal.fire("Good job!", "Actualizado con éxito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    setFile();
    setPhoto([]);
  }

  function jalaFotoPacking(idCont) {
    setPhoto([])
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG_PACKING}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        // console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContenedor(idCont);


    toggleFotoPacking();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuContenedores ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.contenedoresCreate ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ContenedoresCreate"
                >
                  {t("Nuevo")}
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  {t("Nuevo")}
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuContenedores"
                className="btn btn-danger"
                id="botonListado"
              >
                {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>


                <Button
                  size="sm"
                  className="btn"
                  color="warning"
                  onClick={toggleFlete}
                >
                  Fletes <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t("Enviar Listado Contenedores")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                {t("Enviar")}
              </Button>
            </ModalFooter>
            </Modal>

            <Modal size="sm" isOpen={modalFlete} toggle={toggleFlete}>
            <ModalHeader toggle={toggleFlete}>
              <h4>{t("Cargar Fletes")}</h4>
            </ModalHeader>
            <ModalBody>
              {/* <Label className="mr-sm-2">Cargar Fletes</Label> */}
               <Row>

            <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) =>
                        importarArchivo(event)
                      }
                    />
                    </Col>
            </Row>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={guardaFlete}>
                {t("Guardar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Contenedores")}</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={2}>
              <Label className="mr-sm-2">{t("Previo")}</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPrevio}
                onChange={(e) => {
                  setSelectedPrevio(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
            </Col> */}
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNaviera}
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {navierasFiltro
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a}>{a}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>

              {user.tipo == "Broker" ? (
                <td></td>
              ) : (
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedBroker}
                    onChange={(e) => {
                      setSelectedBroker(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">{t("Selecciona")}</option>
                    {brokers
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </td>
              )}

              {user.tipo == "Forwarder" ? (
                <td></td>
              ) : (
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedForwarder}
                    onChange={(e) => {
                      setSelectedForwarder(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">{t("Selecciona")}</option>
                    {forwarders
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.tipo == "Forwarder") {
                          if (a._id == user.forwarders) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        } else {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </td>
              )}

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedSurtido}
                  onChange={(e) => {
                    setSelectedSurtido(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  <option value="Si">{t("Si")}</option>
                  <option value="No">{t("No")}</option>
                </Input>
              </td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPrevio}
                onChange={(e) => {
                  setSelectedPrevio(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if (a.activo == "No") {
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.naviera}</td>
                      <td>{a.clientes}</td>
                      <td>{a.brokers}</td>
                      <td>{a.forwarders}</td>
                      <td>{a.etd}</td>
                      <td>{a.eta}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.flete)}
                      </td>
                      <td></td>
                      {/* <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.totalSurtido
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.pendienteSurtir
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.totalGeneral
                          )}{" "}
                      </td> */}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>CANCELADO</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.naviera}</td>
                      <td>{a.clientes}</td>
                      <td>{a.brokers}</td>
                      <td>{a.forwarders}</td>
                      <td>{a.etd}</td>
                      <td>{a.etaEstimada}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.flete)}
                      </td>
                      <td>{a.incrementable}</td>
                      {/* <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.totalSurtido
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.pendienteSurtir
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.totalGeneral
                          )}{" "}
                      </td> 
                      <td>
                        <>
                          {a.enMar}{" "}
                          {a.enMar == "No" ? (
                            <Button
                              color="primary"
                              onClick={(e) => {
                                marcar(a._id);
                              }}
                              size="sm"
                            >
                              <i class="far fa-check-circle"></i>
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              onClick={(e) => {
                                desMarcar(a._id);
                              }}
                              size="sm"
                            >
                              <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                        </>
                      </td>*/}
                      <td>{a.surtido}</td>
                      <td>{a.fechaEntregaReal}</td>
                      {user.tipo != "Broker" ? (
                        <>
                          <td>
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.clientes,
                                    a.brokers,
                                    a.forwarders,
                                    a.totalCantidad,
                                    a.totalGeneral,
                                    a.observaciones,
                                    a.numero,
                                    a.naviera,
                                    a._id
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                            </>
                          </td>
                          <td>
                            <>
                              {a.previo == "Si" ? (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => jalaFoto(a._id)}
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              {user.contenedoresCreate ? (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    setIdContenedor(a._id);
                                    // setIdControlCalidad(a.idControlCalidad)
                                    toggleSubirFoto();
                                  }}
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              )}
                            </>
                          </td>
                          <td>
                            <>
                              {a.packing == "Si" ? (
                                <>
                                {/* {a.packing} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => jalaFotoPacking(a._id)}
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.packing} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              {user.contenedoresCreate ? (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    setIdContenedor(a._id);
                                    // setIdControlCalidad(a.idControlCalidad)
                                    toggleSubirFotoPacking();
                                  }}
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-upload"></i>
                                </Button>
                              )}
                            </>
                          </td>
                          <td>
                              {user.contenedoresEdit ? (
                                <>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      a.fecha,
                                      a.idClientes,
                                      a.idBrokers,
                                      a.idForwarders,
                                      a.totalCantidad,
                                      a.totalGeneral,
                                      a.observaciones,
                                      a.numero,
                                      a.numeroReferencia,
                                      a.referenciaExtra,
                                      a.flete,
                                      a.incrementable,
                                      a.naviera,
                                      a.referencia,
                                      a.etd,
                                      a.etaEstimada,
                                      a.etaReal,
                                      a.fechaLlegada,
                                      a.fechaLiberacion,
                                      a.fechaEntregaEstimada,
                                      a.fechaEntregaReal,
                                      a.aduana,
                                      a._id
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>


                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditArticulos(
                                  a._id,
                                  a.referencia,
                                )
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                                </>
                              ) : (
                                <>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>

                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                            </>
                              )}
                          </td>
                          <td>
                            {a.surtido == "Si" ||
                            a.totalGasto > 0 ||
                            a.totalSurtido > 0 ||
                            !user.contenedoresCreate ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) => cancelarContenedor(a._id)}
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                              {a.previo == "Si" ? (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disbaled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              ) : (
                                <>
                                {/* {a.previo} */}
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-camera"></i>
                                </Button>
                                </>
                              )}
                              
                              <Button
                                color="success"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-upload"></i>
                              </Button>
                            </>
                          </td>

                          <td>
                              {user.contenedoresEdit ? (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      a.fecha,
                                      a.idClientes,
                                      a.idBrokers,
                                      a.idForwarders,
                                      a.totalCantidad,
                                      a.totalGeneral,
                                      a.observaciones,
                                      a.numero,
                                      a.numeroReferencia,
                                      a.referenciaExtra,
                                      a.flete,
                                      a.incrementable,
                                      a.naviera,
                                      a.referencia,
                                      a.etd,
                                      a.etaEstimada,
                                      a.etaReal,
                                      a.fechaLlegada,
                                      a.fechaLiberacion,
                                      a.fechaEntregaEstimada,
                                      a.fechaEntregaReal,
                                      a.aduana,
                                      a._id
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              )}

                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-cart-plus"></i>
                              </Button>
                          </td>
                          <td>
                            {a.surtido == "Si" ||
                            a.totalGasto > 0 ||
                            a.totalSurtido > 0 ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            {t("Editar")} {t("Contenedor")} {numeroContenedor}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>{t("Fecha")}</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>{t("Numero de Referencia")}</Label>
              <Input
                type="text"
                placeholder="NumeroReferencia"
                value={numeroReferencia}
                required
                onChange={(e) => {
                  setNumeroReferencia(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Referencia Extra")}</Label>
              <Input
                type="text"
                placeholder="ReferenciaExtra"
                value={referenciaExtra}
                required
                onChange={(e) => {
                  setReferenciaExtra(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">{t("Contenedor")}</Label>
              <Input
                type="text"
                placeholder="Referencia"
                value={referencia}
                required
                onChange={(e) => {
                  setReferencia(e.target.value);
                }}
              />
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={3}>
              <Label>{t("Naviera")}</Label>
              <Input
                type="text"
                placeholder="Naviera"
                value={naviera}
                required
                onChange={(e) => {
                  setNaviera(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">{t("Cliente")}</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClienteEdit}
                required
                onChange={(e) => {
                  setSelectedClienteEdit(e.target.value);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            <Col md={3}>
              <Label className="mr-sm-2">Broker</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedBrokerEdit}
                required
                onChange={(e) => {
                  setSelectedBrokerEdit(e.target.value);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {brokers
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={3}>
              <Label className="mr-sm-2">Forwarder</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedForwarderEdit}
                required
                onChange={(e) => {
                  setSelectedForwarderEdit(e.target.value);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {forwarders
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <Label>ETD</Label>
              <Input
                type="date"
                placeholder="Fecha ETD"
                value={fechaETD}
                required
                onChange={(e) => {
                  setFechaETD(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>ETA {t("Puerto")}</Label>
              <Input
                type="date"
                value={fechaETAEstimada}
                required
                onChange={(e) => {
                  setFechaETAEstimada(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Arribo Real Puerto</Label>
              <Input
                type="date"
                value={fechaETAReal}
                required
                onChange={(e) => {
                  setFechaETAReal(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Liberacion Aduana")}</Label>
              <Input
                type="select"
                value={aduana}
                required
                onChange={(e) => {
                  setAduana(e.target.value);
                }}
              >
                <option value="No">No</option>
                <option value="Si">{t("Si")}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            {/* <Col md={3}>
                <Label>Fecha Liberacion</Label>
                <Input
                  type="date"
                  value={fechaLiberacion}
                  required
                  onChange={(e) => {
                    setFechaLiberacion(e.target.value);
                  }}
                />
              </Col> */}
            {/* <Col md={3}>
              <Label>{t("Fecha")} {t("Entrega")} {t("Estimada")}</Label>
                <Input
                  type="date"
                  value={fechaEntregaEstimada}
                  required
                  onChange={(e) => {
                    setFechaEntregaEstimada(e.target.value);
                  }}
                />
              </Col> */}
            <Col md={3}>
              <Label>
                {t("Fecha")} {t("Entrega")} Real
              </Label>
              <Input
                type="date"
                value={fechaEntregaReal}
                required
                onChange={(e) => {
                  setFechaEntregaReal(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{t("Flete")}</Label>
              <Input
                type="number"
                placeholder="Flete"
                value={flete}
                required
                onChange={(e) => {
                  setFlete(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
                <Label>{t("Incrementable")}</Label>
                <Input
                  type="select"
                  placeholder="Incrementable"
                  value={incrementable}
                  required
                  onChange={(e) => {
                    setIncrementable(e.target.value);
                  }}
                >
                 <option value="">{t("Selecciona")}</option>
                 <option value="Si">{t("Si")}</option>
                 <option value="No">{t("No")}</option>
                  
                </Input> 
              </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                {t("Observaciones")}
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editContenedores}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>
            {"Editar"} {t("Articulos")} {t("Contenedor")} {numeroContenedor}
          </h4>
          <h4>
            TOTAL {totalCantidad_edit} pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(totalGeneral_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Articulos")}</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Descripcion")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Cantidad")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Surtido")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Por Surtir")}</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">{t("Precio")}</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Total</Label>
            </Col>
          </Row>

          {articulosContenedor.map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={ao.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ao.id, event);
                    }}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="descripcion"
                    type="text"
                    placeholder="descripcion"
                    value={ao.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={ao.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ao.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="surtido"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="surtido"
                    value={ao.cantidadSurtido}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="pendienteSurtir"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="pendienteSurtir"
                    value={ao.pendienteSurtir}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={ao.precio}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Total"
                    value={ao.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInputExistentes(ao.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="0">{t("Selecciona")}</option>
                    {articulos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="descripcion"
                    type="text"
                    placeholder="descripcion"
                    value={inputField.descripcion}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="surtido"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.cantidadSurtido}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="pendienteSurtir"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.pendienteSurtir}
                    required
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={inputField.precio}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Total"
                    value={inputField.total}
                    disabled
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={6}>
              <h4 id="logoutBoton">
                TOTAL {totalCantidad_edit} pzas. /{" "}
                {"$" + new Intl.NumberFormat("en-US").format(totalGeneral_edit)}
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={saveContenedor}>
            {t("Guardar")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>{t("Progreso")}</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            {t("Este proceso puede tardar varios segundos.")}
            <br />
            {t("Por favor no cierre ni refresque su navegador.")}
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalSubirFoto} toggle={toggleSubirFoto}>
        <ModalHeader toggle={toggleSubirFoto}>
          <h4>Subir Fotos</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label>Selecciona un archivo</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
          <Button size="sm" color="success" onClick={guardaFotos}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalSubirFotoPacking} toggle={toggleSubirFotoPacking}>
        <ModalHeader toggle={toggleSubirFotoPacking}>
          <h4>Subir Fotos</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                  <Col md={12}>
              <Label>
                Selecciona los Archivos
              </Label>
              <Input
                type="file"
                multiple
                onChange={(e) => {
                  setFile(Array.from(e.target.files));
                  // setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row>
          <Button size="sm" color="success" onClick={guardaFotosPacking}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>


      <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
  <ModalHeader toggle={toggleFoto}>
    <h4>Fotos</h4>
  </ModalHeader>
  <ModalBody>
    <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
  </ModalBody>
</Modal>

    
    <Modal size="xxl" isOpen={modalFotoPacking} toggle={toggleFotoPacking}>
      <ModalHeader toggle={toggleFotoPacking}>
        <h4>Packing List</h4>
      </ModalHeader>
      <ModalBody>
      <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
      </ModalBody>
    </Modal>

      {loader}
    </>
  );
}

export default ListadoContenedores;
