import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ListadoSurtidos() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;
  const URL_DELMEDIA = process.env.REACT_APP_URL_DELMEDIA;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [t, i18n] = useTranslation("global");
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedFacturado, setSelectedFacturado] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  const [cantidadFacturas, setCantidadFacturas] = useState(1);
  const [idSurt, setIdSurt] = useState();
  const [idCont, setIdCont] = useState();
  const [modalFacturas, setModalFacturas] = useState(false);
  const toggleFacturas = () => setModalFacturas(!modalFacturas);


  const [photo, setPhoto] = useState([]);
  const [idSurtido, setIdSurtido] = useState();
  // const [file, setFile] = useState();
  const [file, setFile] = useState([]);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  const [modalSubirFoto, setModalSubirFoto] = useState(false);
  const toggleSubirFoto = () => setModalSubirFoto(!modalSubirFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(()=>{
    axios
    .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              broker: a.contenedores[0].brokers[0].name,
              idBroker: a.contenedores[0].brokers[0]._id,
              numeroReferencia: a.contenedores[0].numeroReferencia,
              referenciaExtra: a.contenedores[0].referenciaExtra,
              referencia: a.contenedores[0].referencia,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              impuestos: a.impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.totalDinero,
              montoServicio: a.montoServicio,
              facturado: a.facturado,
              idContenedor: a.contenedores[0]._id
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio ,selectedFechaFin])

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    doc.save(`Surtidos.pdf`);
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          id: a._id,
          Numero: a.numero,
          Fecha: a.fecha,
          Referencia: a.numeroReferencia,
          Contenedor: a.referencia,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Surtio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["id", "Numero", "Fecha", "Referencia", "Contenedor", "Cliente", "Pedido", "Surtio"],
        sheetHeader: ["id", "Numero", "Fecha", "Referencia", "Contenedor", "Cliente", "Pedido", "Surtio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Pedido", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    impuestos,
    subTotal,
    iva,
    montoServicio,
    total_general,
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              articulos:a.articulos[0].name,
              descripcion: a.articulosContenedor[0].descripcion,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });


        let agrupadoValues = Object.values(arrayTabla);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);


        let totalParcial = 0;

        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.articulos,
            a.descripcion,  
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 20, 18);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`Nota:`, 35, 20);
        doc.text(`${numero}`, 60, 20);
        doc.text(`Cliente:`, 35, 25);
        doc.text(`${clientes}`, 60, 25);

        doc.autoTable({
          head: [["Articulo", "Descripcion", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 30,
          foot: [
            [
              "",
              "",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `IVA`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              `Monto Servicio`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(montoServicio.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
                
              }).format(total_general + montoServicio),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function excelFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    impuestos,
    subTotal,
    iva,
    total_general,
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          return groups;
        }, []);
        let agrupadoValues = Object.values(agrupado);
        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        let totalParcial = 0;
        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return {
            Articulos: a.articulos,
            Cantidad: a.cantidad,
            Precio: a.precio,
            TotalParcial: totalParcial,
          };
        });
        const dataExportExcel = Object.values(data);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        const data2 = [
          {
            Cliente: clientes,
            Nota: numero,
            Impuestos: impuestos,
            IVA: iva,
            TotalGeneral: total_general,
            Fecha: fecha,
            Observaciones: observaciones,
          },
        ];

        var option = {};
        option.fileName = `Nota ${numero}`;
        option.datas = [
          {
            sheetData: data2,
            sheetName: `Datos Generales`,
            sheetFilter: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "Observaciones",
            ],
            sheetHeader: [
              "Nota",
              "Fecha",
              "Cliente",
              "RFC",
              "Direccion",
              "Impuestos",
              "IVA",
              "TotalGeneral",
              "Observaciones",
            ],
          },
          {
            sheetData: dataExcelLimpia,
            sheetName: `Articulos`,
            sheetFilter: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
            sheetHeader: ["Articulos", "Cantidad", "Precio", "TotalParcial"],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroReferencia", sortable: true},
    { name: `${t("Ref. Extra")}`, field: "referenciaExtra", sortable: true},
    { name: `${t("Contenedor")}`, field: "referencia", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    { name: `${t("Broker")}`, field: "Broker", sortable: true },
    { name: `${t("Surtio")}`, field: "user", sortable: true },
    { name: `${t("Facturado")}`, field: "facturado", sortable: false },
    { name: `${t("Detalles")}`, field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numeroReferencia
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.referenciaExtra.toLowerCase().includes(search.toLowerCase()) ||
          comment.referencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.broker.toLowerCase().includes(search.toLowerCase()) ||
          comment.facturado.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFacturado) {
      computedComments = computedComments.filter((e) =>
        e.facturado.includes(selectedFacturado)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    selectedFacturado
  ]);

  function facturarMod(id, idContenedor,){

    setIdSurt(id)
    setIdCont(idContenedor)
    toggleFacturas()
  }

    function facturar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a facturar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, facturar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_SURTIDOS}Factura/${idSurt}`,
            {
              facturado: "Si",
              cantidadFacturas,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se facturo",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function desFacturar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se quitara la facturacion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quitar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_SURTIDOS}Factura/${id}`,
            {
              facturado: "No",
              cantidadFacturas: 0
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se actualizo",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function guardaFotos(){
    file.map((a)=>{
    const formData = new FormData();
    formData.append("file", a);
    formData.append("contenedor", idSurtido );

    axios
      .post(URL_UPMEDIA, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })

    axios.post(
      URL_LOGS,
      {
        tipo: "Factura",
        detalle: `Subir Foto`,
        user: user.id,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    );
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Good Job!",
      showConfirmButton: false,
      timer: 2000,
    });
  })
    setFile([])
    setPhoto([])
  }
  function jalaFoto(idCont, idSurt) {
    const URL_GET_MEDIA_CONT = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idCont}`;
    const URL_GET_MEDIA_SURT = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idSurt}`;
  
    Promise.all([
      axios.get(URL_GET_MEDIA_CONT, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`,
        },
      }),
      axios.get(URL_GET_MEDIA_SURT, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`,
        },
      })
    ])
    .then(([responseCont, responseSurt]) => {
      const photosCont = responseCont.data.resources;
      const photosSurt = responseSurt.data.resources;
      const allPhotos = [...photosCont, ...photosSurt];
      setPhoto(allPhotos);
    })
    .catch((err) => console.log(err));
    
    // setIdSurtido(idSurt);
    toggleFoto();
  }

  function borrarFoto(id) {

    axios.post(`${URL_DELMEDIA}/${id}`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then(() => {

      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    // setFile();
    // setPhoto([]);
  }
console.log("hola", photo)
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuVentas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuContenedores"
                className="btn btn-danger"
                id="botonListado"
              >
                 {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={
                    e=>{
                      axios
                      .get(`${URL_SURTIDOS}Desarrollo`, {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      })
                      .then((response) => {
                        alert(response)
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    }
                  }
                >
                  Desarrollo <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{("Enviar Listado de Notas")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
               {t("Enviar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Notas")}</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>{t("Clientes")}</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">{t("Selecciona")}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>{t("Facturado")}</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedFacturado}
                onChange={(e) => {
                  setSelectedFacturado(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if (a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.clientes}</td>
                      <td>{a.broker}</td>
                      <td>{a.user}</td>
                      <td>{a.facturado}</td>
                        <td>CANCELADA
                          <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.montoServicio,
                                  a.total_general,
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button></td>
                    </tr>
                  );
                }else{
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.clientes}</td>
                      <td>{a.broker}</td>
                      <td>{a.user}</td>
                      <td>
                        <>
                        {a.facturado} {" "}
                        {a.facturado == "No" ? (
                            <Button
                              color="primary"
                              onClick={(e) => {
                                facturarMod(a._id, a.idContenedor);
                              }}
                              size="sm"
                            >
                              <i class="far fa-check-circle"></i>
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              onClick={(e) => {
                                desFacturar(a._id, a.idContenedor);
                              }}
                              size="sm"
                            >
                              <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                          </>
                        </td>
                      <td>
                        <>
                          
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.idClientes,
                                a.clientes,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.user,
                                a.impuestos,
                                a.subTotal,
                                a.iva,
                                a.montoServicio,
                                a.total_general
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={(e) =>
                              excelFOC(
                                a.fecha,
                                a.idClientes,
                                a.clientes,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.user,
                                a.impuestos,
                                a.subTotal,
                                a.iva,
                                a.total_general
                              )
                            }
                          >
                            <i class="far fa-file-excel"></i>
                          </Button>

                          <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a.idContenedor, a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>{
                                setIdSurtido(a.idContenedor)
                                toggleSubirFoto()
                              }}
                            >
                              <i class="fas fa-upload"></i>
                            </Button>
                        </>
                      </td>
                    </tr>
                  );
                }

              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />


      <Modal size="sm" isOpen={modalSubirFoto} toggle={toggleSubirFoto}>
        <ModalHeader toggle={toggleSubirFoto}>
          <h4>
            Subir Archivos
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                  <Col md={12}>
              <Label>
                Selecciona los Archivos
              </Label>
              <Input
                type="file"
                multiple
                onChange={(e) => {
                  setFile(Array.from(e.target.files));
                  // setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row>
            <Button size="sm" color="success" onClick={guardaFotos}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>


      <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
  <ModalHeader toggle={toggleFoto}>
    <h4>Fotos</h4>
  </ModalHeader>
  <ModalBody>
    <Row>
      {photo.length > 0 ? (
        photo.map((item) => (
          <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
            {item.format === "pdf" ? (
              <embed
                src={item.secure_url}
                type="application/pdf"
                width="500"
                height="600"
              />
            ) : item.format === "xls" || item.format === "xlsx" ? (
              <a href={item.secure_url} download>
                <Button color="primary">
                  Descargar {item.original_filename}. {item.format}
                </Button>
              </a>
            ) : (
              <img
                src={item.secure_url}
                alt="Uploaded Image"
                width="250"
                height="auto"
              />
            )}
            <div>
              <Button
                color="danger"
                onClick={() => borrarFoto(item.asset_id)}
                style={{ marginTop: "10px" }}
              >
                Eliminar
              </Button>
            </div>
          </div>
        ))
      ) : (
        <h4>No se ha subido una Foto, PDF o archivo Excel....</h4>
      )}
    </Row>
  </ModalBody>
</Modal>


      <Modal size="sm" isOpen={modalFacturas} toggle={toggleFacturas}>
        <ModalHeader toggle={toggleFacturas}>
          <h4>Marcar Facturado</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
        <Col md={6}>
              <Label className="mr-sm-2">Cantidad Facturas</Label>
              <Input
                bsSize="sm"
                className="col-sm-12"
                type="number"
                value={cantidadFacturas}
                required
                onChange={(e) => {
                  setCantidadFacturas(e.target.value);
                }}
              />
            </Col>
            </Row>
            <br />
          <Button size="sm" color="success" onClick={e=>facturar()} tabindex="-1">
            Guardar
          </Button>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoSurtidos;
