import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
function BotonesContenedores() {
  const { user } = useContext(AuthContext);
  const [t, i18n] = useTranslation("global");
  return (
    <>
          <Header />
          {user.menuContenedores ?(
      <div className="container">
        <br />
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menuContenedores ?(
            <Button href="/ListadoContenedores" className="botonesMenu" color="success">
              <i class="fas fa-box fa-7x"></i>
              <br />
              <br />
              {t("Contenedores")}
            </Button>
          ): undefined }
          </Col>

          <Col md={4} align="center">
          {user.menuSurtidos ?(
            <Button href="/ListadoPendienteSurtir" className="botonesMenu" color="success">
              <i class="fas fa-truck fa-7x"></i>
              <br />
              <br />
              {t("Surtir")}
            </Button>
          ): undefined }
          </Col>

          <Col md={4} align="center">
          {user.menuVentas ?(
            <Button href="/ListadoSurtidos" className="botonesMenu" color="success">
              <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              {t("Ventas")}
            </Button>
          ): undefined }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesContenedores;
