import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Catalgos
import BotonesCatalogos from './views/Catalogos/BotonesCatalogos'
import ListadoArticulos from "./views/Catalogos/Articulos/ListadoArticulos";
import ListadoNavieras from "./views/Catalogos/Navieras/ListadoNavieras";
import ListadoEmpresas from "./views/Catalogos/Empresas/ListadoEmpresas";
import ListadoBrokers from "./views/Catalogos/Brokers/ListadoBrokers";
import ListadoForwarders from "./views/Catalogos/Forwarders/ListadoForwarders";
import ListadoClientes from "./views/Catalogos/Clientes/ListadoClientes"
import ClientesCreate from "./views/Catalogos/Clientes/ClientesCreate"


// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin"
import ListadoAbonos from "./views/Admin/Abonos/ListadoAbonos"
import AbonosCreate from "./views/Admin/Abonos/AbonosCreate"
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";

// Gastos
import BotonesGastos from "./views/Gastos/BotonesGastos"
import ListadoGastosContenedor from './views/Gastos/GastosContenedor/ListadoGastosContenedor'
import GastosContenedorCreate from './views/Gastos/GastosContenedor/GastosContenedorCreate'
import ListadoConceptosGastos from './views/Gastos/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/Gastos/ConceptosGastos/ConceptosGastosCreate'


//Reportes
import BotonesReportes from "./views/Reportes/BotonesReportes";
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import GraficaVentasAnual from "./views/Reportes/GraficaVentasAnual";
import GraficaAbonosMensual from "./views/Reportes/GraficaAbonosMensual";

// Whatsapp
import Whatsapp from "./views/Reportes/Whatsapp";

// Contenedores
import BotonesContenedores from "./views/Contenedores/BotonesContenedores";
import ContenedoresCreate from "./views/Contenedores/Contenedores/ContenedoresCreate";
import ListadoContenedores from "./views/Contenedores/Contenedores/ListadoContenedores";
import ReporteContenedores from "./views/Reportes/ReporteContenedores";

// Surtidos
import ListadoPendienteSurtir from "./views/Contenedores/Surtidos/ListadoPendienteSurtir";
import ListadoSurtidos from "./views/Contenedores/Surtidos/ListadoSurtidos";

// Abonos Gastos
import ListadoAbonosGastos from "./views/Gastos/AbonosGastos/ListadoAbonosGastos";
import AbonosGastosCreate from "./views/Gastos/AbonosGastos/AbonosGastosCreate";
import EstadoCuentaCXP from "./views/Gastos/EstadoCuentaCXP/EstadoCuentaCXP";



function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                 {/* Catalogos */}
                 <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                 <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                 <Route exact path ="/ListadoNavieras" component={ListadoNavieras} />
                 <Route exact path ="/ListadoEmpresas" component={ListadoEmpresas} />
                 <Route exact path ="/ListadoBrokers" component={ListadoBrokers} />
                 <Route exact path ="/ListadoForwarders" component={ListadoForwarders} />
                 <Route exact path ="/ListadoClientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                {/* Abonos */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Gastos" component={ListadoGastosContenedor} />
                <Route exact path ="/GastosCreate" component={GastosContenedorCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                 {/* Reportes */}
                 <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/GraficaVentasMensual" component={GraficaVentasMensual} />
                <Route exact path ="/GraficaVentasAnual" component={GraficaVentasAnual} />
                <Route exact path ="/GraficaAbonosMensual" component={GraficaAbonosMensual} />

                {/* Whatsapp */}
                <Route exact path ="/Whatsapp" component={Whatsapp} />

                {/* Contenedores */}
                <Route exact path ="/MenuContenedores" component={BotonesContenedores} />
                <Route exact path ="/ContenedoresCreate" component={ContenedoresCreate} />
                <Route exact path ="/ListadoContenedores" component={ListadoContenedores} />
                <Route exact path ="/ReporteContenedores" component={ReporteContenedores} />

                {/* Abonos Contenedores */}
                <Route exact path ="/AbonosGastosCreate" component={AbonosGastosCreate} />
                <Route exact path ="/ListadoAbonosGastos" component={ListadoAbonosGastos} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />

                {/* Surtidos */}
                <Route exact path ="/ListadoPendienteSurtir" component={ListadoPendienteSurtir} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />


            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
